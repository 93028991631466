import React from "react";
import { Switch } from "react-router-dom";

import { Route } from "./components";
import {
  Landing,
  Form,
  Login,
  Results,
  SignUp,
  RequestForm,
  BackOffice,
  Seller,
  BackOfficeDetails,
  SellerDetails,
  ForgotPassword,
  ResetPassword,
} from "./pages";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/vender" isPrivate exact component={Form} />
      <Route path="/comprar" exact component={Form} />
      <Route path="/login" component={Login} />
      <Route path="/resultados" component={Results} />
      <Route path="/cadastro" component={SignUp} />
      <Route path="/solicitacao" isPrivate component={RequestForm} />
      <Route path="/backoffice" isPrivate exact component={BackOffice} />
      <Route path="/vendedor" isPrivate exact component={Seller} />
      <Route path="/esqueci-a-senha" exact component={ForgotPassword} />
      <Route path="/reset_senha/:hash" exact component={ResetPassword} />
      <Route
        path="/backoffice/:cpf/:dateTime"
        isPrivate
        component={BackOfficeDetails}
      />
      <Route
        path="/vendedor/:cpf/:dateTime"
        isPrivate
        component={SellerDetails}
      />
    </Switch>
  );
};

export default Routes;
