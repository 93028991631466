import { fetchWrapper as fetch } from ".";
import {
  nogordAPI,
  bankProposalEndPoint,
  token,
  proposalEndPoint,
  updateProposalEndPoint,
} from "../config/env";
import { IRequestType } from "../interfaces";
import { request } from "https";

class RequestService {
  static sendRequest(
    requestData: IRequestType,
    bank: string,
    seller_code: string
  ) {
    const mobileMatch = requestData.phone.match(/\(([^)]+)\)/);
    const residencialMatch = requestData.residencialPhone.match(/\(([^)]+)\)/);
    const comercialMatch = requestData.comercialPhone.match(/\(([^)]+)\)/);
    const referenceMatch = requestData.referencePhone.match(/\(([^)]+)\)/);
    const data = {
      name: requestData.name,
      cpf: requestData.cpf.replace(/\./g, "").replace("-", ""),
      birthDate: requestData.birthday,
      Email: requestData.email,
      mobile_areaCode:
        mobileMatch && mobileMatch?.length >= 2 ? mobileMatch[1] : "",
      mobile_number: requestData.phone.substr(4).replace(/[ |-]/g, ""),
      gender: requestData.gender,
      phone_areaCode:
        residencialMatch && residencialMatch?.length >= 2
          ? residencialMatch[1]
          : "",
      phone_number: requestData.residencialPhone
        .substr(4)
        .replace(/[ |-]/g, ""),
      mother_name: requestData.mothersName,
      father_name: requestData.fathersName,
      ID_number: requestData.rg,
      ID_issue_date: requestData.emissionDate,
      ID_issuing_institution: requestData.emissionAgency,
      ID_state: requestData.documentUF,
      nationality: requestData.nationality,
      birth_place: requestData.naturalness,
      marital_status: requestData.maritalStatus,
      spouse_name: requestData.spousesName,
      spouse_cpf: requestData.cpf,
      assets_value: requestData.patrimonyTotalValue,
      occupation_nature: requestData.natureOfOcupation,
      job_detail: requestData.ocupationDetail,
      employed_time: requestData.ocupationTime,
      total_monthly_income: requestData.totalMonthlyIncome,
      commercial_phone_areaCode:
        comercialMatch && comercialMatch?.length >= 2 ? comercialMatch[1] : "",
      commercial_phone_number: requestData.comercialPhone
        .substr(4)
        .replace(/[ |-]/g, ""),
      address_cep: requestData.cep,
      address_street: requestData.street,
      address_number: requestData.number,
      address_complement: requestData.complement,
      address_neighborhood: requestData.neighborhood,
      address_uf: requestData.uf,
      address_city: requestData.city,
      residential_property_situation: requestData.propertySituation,
      referred_name: requestData.reference,
      referred_phone_areaCode:
        referenceMatch && referenceMatch?.length >= 2 ? referenceMatch[1] : "",
      referred_phone_number: requestData.referencePhone
        .substr(4)
        .replace(/[ |-]/g, ""),
      car_manufactureYear: requestData.fabricationYear,
      car_modelYear: requestData.modelYear,
      car_manufacturer: requestData.carBrand,
      car_model: requestData.carModel,
      car_version: requestData.carVersion,
      car_value: Number.parseFloat(
        (requestData.carPrice || "0")
          ?.replace(".", "")
          ?.replace(",", ".")
          ?.replace("R$", "")
          .trim()
      ),
      car_entryValue: Number.parseFloat(
        (requestData.downPayment || "0")
          ?.replace(".", "")
          ?.replace(",", ".")
          ?.replace("R$", "")
          .trim()
      ),
      seller_type: requestData.seller,
      rate_table: requestData.taxTable,
      installments_number: requestData.installments,
      best_payment_day: requestData.bestDayForPayment,
      car_licence_plate: requestData.plate,
      car_licence_plate_uf: requestData.plateCurrentUF,
      car_licensing_uf: requestData.plateFutureUF,
      car_mileage: requestData.mileage,
      car_renavam: requestData.RENAVAM,
      car_chassis: requestData.CHASSI,
      car_fuel: requestData.fuel,
      car_color: requestData.carColor,
      bank,
      seller_code,
      observations: requestData.observations,
    };
    return fetch(`${nogordAPI}/${bankProposalEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify(data),
    });
  }
  static list(seller_code: string) {
    return fetch(`${nogordAPI}/${proposalEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({ seller_code }),
    });
  }
  static get(cpf: string, dateTime: string, seller_code: string) {
    return fetch(`${nogordAPI}/${proposalEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({ seller_code, cpf, dateTime }),
    });
  }
  static update(cpf: string, dateTime: string, status: string) {
    return fetch(`${nogordAPI}/${updateProposalEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({
        status,
        cpf,
        dateTime,
      }),
    });
  }
}

export default RequestService;
