import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import buyIcon from "../../assets/img/icons/magnifier.png";
import sellIcon from "../../assets/img/icons/car.png";
import "./style.css";
import { useAuth } from "../../hooks/auth";

function Landing() {
  const { user, signed } = useAuth();

  function route() {
    if (!signed) return { route: "/login", label: "Entrar" };
    if (!user.seller_code)
      return { route: "/backoffice", label: "Back Office" };
    return { route: "/vendedor", label: "Propostas" };
  }

  return (
    <div id="landingPage" className="container">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <Link to={route().route} className="login-button">
        {route().label}
      </Link>
      <div className="buttonContainer">
        <Link to="/comprar">
          <span>Quero Comprar</span> <img src={buyIcon} alt="Quero comprar" />
        </Link>
        <Link to="/vender">
          <span>Quero Vender</span> <img src={sellIcon} alt="Quero vender" />
        </Link>
      </div>
      <a href="http://sistema.parcelajusta.com" className="aboutUs">
        Sobre Nós
      </a>
    </div>
  );
}

export default Landing;
