import React from "react";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { EmailOutlined, KeyboardBackspace } from "@material-ui/icons/";
import { IStepComponent } from "../../interfaces";
import { useToast } from "../../hooks/toast";
import "./style.css";
import { Link } from "react-router-dom";
import { UserService } from "../../services";

function ForgotPassword() {
  const { addToast } = useToast();
  const [email, setEmail] = React.useState("");
  const [sent, setSent] = React.useState(false);

  function handleChange(e: React.FormEvent) {
    const { value } = e.target as HTMLInputElement;
    setEmail(value);
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const response = await UserService.forgotPassword({ email });
    if (!response?.data?.success) 
      addToast({
        title: "Erro",
        description: response?.data?.message || response.error,
        type: "error",
      })
      else
        setSent(true);
  }

  return (
    <div id="ForgotPassword" className="container">
      {!sent ?
      <>
      <div className="label">
        <div className="validation">RECUPERAÇÃO DE SENHA</div>
        <div className="come-back">Identifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="text-field">
          <TextField
            fullWidth
            label="E-mail"
            onChange={handleChange}
            value={email}
            type="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="button">
          <button className="full-width" type="submit">
            Enviar
          </button>
        </div>
        <Link to="/login" className="prev">
           <span>Voltar</span>
        </Link>
      </form>
      </> 
      : <div className="sent">
        <div className="label">
          <div className="validation">
            E-MAIL ENVIADO.
          </div>
        </div>
        <div className="button">
          <Link className="link-button" to={'/login'}>
            Voltar
          </Link>
        </div>
      </div>
      }
    </div>
  );
}

export default ForgotPassword;
