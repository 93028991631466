export const apiURL: string = "https://criatech.me/compra-inteligente-api";
export const fipeURL: string = "https://parallelum.com.br/fipe/api/v1";
export const nogordAPI: string = "https://engine.nogord.io/api/v1/le/execute";
export const signUpEndPoint: string =
  "98cb3f35-fea8-4386-a9f1-3c73102f5be5/d5c8d6c0-6b41-4cb2-844f-cfdc6ece2650.json";
export const signInEndPoint: string =
  "9cfbd0d2-2787-42f7-bbc4-5bd654ae6264/56206ec9-fe0c-4672-94b2-b3eacbfb289f.json";
export const token: string = "b4006b9a-d938-4ef9-9548-308d02456e39";
export const bankProposalEndPoint: string =
  "beb4670f-b456-4b9c-a6cf-1a9c9e268805/0ea51712-682c-4f27-9a6a-8b1b60da2c16.json";
export const proposalEndPoint: string =
  "8c3a0eaa-64f5-4098-b510-f23f72edc491/891f2c56-6ca8-4a74-a733-4d8b9e61cc03.json";
export const updateProposalEndPoint: string =
  "f0fd57cc-873d-4960-b5e5-18055a51bc32/6b198e56-f336-4e63-b4e5-7e5553246233.json";
export const preSignUpEndPoint: string =
  "9aa8c3ed-5c4a-4498-9e3c-d6586ff98086/a2367505-70f8-4be5-9024-12d79c87e58c.json";
export const startCafEndPoint: string =
  "233ffa04-febc-4fde-ba7b-8cae0edfb686/68b3db39-a392-4d05-9536-bda5c85e0df8.json";
export const resetPasswordEndPoint: string = 
  "29a1c200-365c-4f0b-89bf-d774d141a4e6/f585348f-7054-417b-a679-91df7c73e2a6.json";
export const forgotPasswordEndPoint: string = 
  "9fb8353b-4caa-445b-84da-0e08469c756f/4e453b73-ac8a-415e-90c1-ce2dcbb475aa.json";
