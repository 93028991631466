import { fetchWrapper as fetch } from ".";
import {
  nogordAPI,
  signInEndPoint,
  signUpEndPoint,
  token,
  preSignUpEndPoint,
  startCafEndPoint,
  resetPasswordEndPoint,
  forgotPasswordEndPoint,
} from "../config/env";
import { TSignUpData, TSignInData, TResetPassword, TForgotPassword } from "../interfaces";

class UserService {
  static signUp(data: TSignUpData) {
    const mobileMatch = data.mobile_number.match(/\(([^)]+)\)/);
    const phoneMatch = data.phone_number.match(/\(([^)]+)\)/);
    return fetch(`${nogordAPI}/${signUpEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({
        ...data,
        sellerCPF: data.sellerCPF.replace(/\./g, "").replace("-", ""),
        mobile_areaCode:
          mobileMatch && mobileMatch?.length >= 2 ? mobileMatch[1] : "",
        phone_areaCode:
          phoneMatch && phoneMatch?.length >= 2 ? phoneMatch[1] : "",
        mobile_number: data.mobile_number.substr(4).replace(/[ |-]/g, ""),
        phone_number: data.phone_number.substr(4).replace(/[ |-]/g, ""),
      }),
    });
  }

  static preSignUp(cpf: string) {
    return fetch(`${nogordAPI}/${preSignUpEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({ cpf: cpf.replace(/\./g, "").replace("-", "") }),
    });
  }
  static startCAF(cpf: string, phone: string, email: string) {
    const mobileMatch = phone.match(/\(([^)]+)\)/);
    return fetch(`${nogordAPI}/${startCafEndPoint}`, {
      method: "POST",
      headers: { Authorization: token },
      body: JSON.stringify({
        cpf: cpf.replace(/\./g, "").replace("-", ""),
        mobile_areaCode:
          mobileMatch && mobileMatch?.length >= 2 ? mobileMatch[1] : "",
        mobile_number: phone.substr(4).replace(/[ |-]/g, ""),
        email,
      }),
    });
  }
  static signIn(data: TSignInData) {
    return fetch(`${nogordAPI}/${signInEndPoint}`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
  }
  static forgotPassword(data: TForgotPassword) {
    return fetch(`${nogordAPI}/${forgotPasswordEndPoint}`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
  }
  static resetPassword(data: TResetPassword) {
    return fetch(`${nogordAPI}/${resetPasswordEndPoint}`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: JSON.stringify(data),
    });
  }
}

export default UserService;
