import React from "react";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { EmailOutlined, KeyboardBackspace } from "@material-ui/icons/";
import { IStepComponent } from "../../interfaces";
import { useToast } from "../../hooks/toast";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import passwordIcon from "../../assets/img/icons/password.png";
import ClosedEyeIcon from "@material-ui/icons/VisibilityOffOutlined";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import { UserService } from "../../services";

function ResetPassword() {
  const { addToast } = useToast();
  const { hash } = useParams();
  const [hiddenPassword, setHiddenPassword] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [changed, setChanged] = React.useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if(password !== passwordConfirmation){
      setError(true);
      return;
    }
    const response = await UserService.resetPassword({ hash, newPassword: password });
    if (!response?.data?.success) 
      addToast({
        title: "Erro",
        description: response?.data?.message || response.error,
        type: "error",
      })
      else
        setChanged(true);
  }

  function toggleVisibility() {
    setHiddenPassword(!hiddenPassword);
  }

  function handleChange(e: React.FormEvent) {
    const { value } = e.target as HTMLInputElement;
    setPassword(value);
  }

  function handleConfirmationChange(e: React.FormEvent) {
    const { value } = e.target as HTMLInputElement;
    setPasswordConfirmation(value);
  }


  return (
    <div id="ResetPassword" className="container">
      {!changed ?
      <>
      <div className="label">
        <div className="validation">ALTERE SUA SENHA</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="text-field">
          <TextField
            fullWidth
            type={hiddenPassword ? "password" : "text"}
            label="Nova senha"
            name="password"
            value={password}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordIcon}
                    alt="Ícone de senha"
                    className="icon"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <span className="passwordButton" onClick={toggleVisibility}>
                    {hiddenPassword ? <EyeIcon /> : <ClosedEyeIcon />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={handleConfirmationChange}
            type={hiddenPassword ? "password" : "text"}
            label="Confirmação de senha"
            helperText={
              error
                ? "Senha e confirmação de senha devem ser idênticas"
                : undefined
            }
            FormHelperTextProps={{ style: { color: "#F33", fontSize: "1rem" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordIcon}
                    alt="Ícone de senha"
                    className="icon"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <span className="passwordButton" onClick={toggleVisibility}>
                    {hiddenPassword ? <EyeIcon /> : <ClosedEyeIcon />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="button">
          <button className="full-width" type="submit">
            Alterar senha
          </button>
        </div>
      </form>
      </>
      :
      <div className="sent">
        <div className="label">
          <div className="validation">
            SENHA ALTERADA COM SUCESSO!
          </div>
        </div>
        <div className="button">
          <Link className="link-button" to={'/login'}>
            Fazer login
          </Link>
        </div>
      </div>}
    </div>
  );
}

export default ResetPassword;
