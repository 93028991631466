import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../hooks/auth";

const RouteWrapper: React.FC<{
  component: any;
  isPrivate?: boolean;
  path: string;
  exact?: boolean;
}> = ({ component: Component, isPrivate, path, exact }) => {
  const { signed } = useAuth();
  return isPrivate ? (
    signed ? (
      <Route path={path} exact={exact}>
        <Component />
      </Route>
    ) : (
      <Redirect to="/login" />
    )
  ) : (
    <Route path={path} exact={exact}>
      <Component />
    </Route>
  );
};

export default RouteWrapper;
