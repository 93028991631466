import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DirectionsCarOutlined,
  CalendarTodayOutlined,
  MonetizationOnOutlined,
  LocalOfferOutlined,
  PlayCircleOutlineOutlined,
  PermIdentityOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
  CalendarToday,
  AssignmentOutlined,
  PlaceOutlined,
} from "@material-ui/icons/";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useLocation, Link, useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-format";
import ReactInputMask from "react-input-mask";
import DateMomentUtils from "@date-io/moment";
import "./style.css";

import logoImg from "../../assets/img/logo.png";
import { IFormType, IErrorType } from "../../interfaces";
import { FormLabel, InputAdornment, FormHelperText } from "@material-ui/core";
import { UFList } from "../../config/UFList";
import { FipeService } from "../../services";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useAuth } from "../../hooks/auth";

interface ICarResultType {
  codigo: string;
  nome: string;
}

function Form() {
  const [knowCar, setKnowCar] = React.useState<boolean>(true);
  const [data, setData] = React.useState<IFormType>({
    brand: "",
    model: "",
    year: undefined,
    value: undefined,
    entryValue: undefined,
    name: "",
    cpf: "",
    uf: "",
    birthday: null,
    email: "",
    phone: "",
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [brands, setBrands] = React.useState<Array<ICarResultType>>([]);
  const [models, setModels] = React.useState<Array<ICarResultType>>([]);
  const [years, setYears] = React.useState<Array<ICarResultType>>([]);
  const [
    selectedBrand,
    setSelectedBrand,
  ] = React.useState<ICarResultType | null>(null);
  const [
    selectedModel,
    setSelectedModel,
  ] = React.useState<ICarResultType | null>(null);
  const [errors, setErrors] = React.useState<IErrorType>({});
  const { user } = useAuth();

  const { pathname } = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    setData({ ...data, model: selectedModel?.nome });
  }, [selectedModel]);

  React.useEffect(() => {
    setData({ ...data, brand: selectedBrand?.nome });
  }, [selectedBrand]);

  const getModels = (customBrands?: Array<ICarResultType>) => {
    setLoading(true);
    FipeService.getModels(
      (customBrands || brands).find((brand) => brand.nome === data.brand)
        ?.codigo
    )
      .then((result) => {
        if (result.ok) {
          setModels(result.data.modelos);
          if (data.model) {
            setSelectedModel(
              result.data.modelos.find(
                (model: ICarResultType) => model.nome === data.model
              )
            );
          }
          if (data.year) {
            getYears(customBrands, result.data.modelos);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const getYears = (
    customBrands?: Array<ICarResultType>,
    customModels?: Array<ICarResultType>
  ) => {
    setLoading(true);
    FipeService.getYears(
      (customBrands || brands).find((brand) => brand.nome === data.brand)
        ?.codigo,
      (customModels || models).find((model) => model.nome === data.model)
        ?.codigo
    )
      .then((result) => {
        if (result.ok) {
          setYears(result.data);
        }
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    FipeService.getBrands()
      .then((result) => {
        if (result.ok) {
          setBrands(result.data);
          if (data.brand) {
            setSelectedBrand(
              result.data.find(
                (brand: ICarResultType) => brand.nome === data.brand
              )
            );
          }
          if (data.model) {
            getModels(result.data);
          }
        }
      })
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    getModels();
  }, [data.brand]);

  React.useEffect(() => {
    getYears();
  }, [data.model]);

  React.useEffect(() => {
    setData({
      ...data,
      entryValue: (getCurrencyNumber("value") * 0.3).toString(),
    });
  }, [data.value]);

  function handleKnowCarClick() {
    setKnowCar(true);
  }
  function handleDontKnowCarClick() {
    setKnowCar(false);
  }

  function updateData(name: string, value: string) {
    const {
      brand,
      model,
      year,
      value: carValue,
      name: fullName,
      entryValue,
      cpf,
      uf,
      birthday,
      email,
      phone,
    } = data as IFormType;
    setData({
      brand,
      model,
      year,
      value: carValue,
      entryValue,
      name: fullName,
      cpf,
      uf,
      birthday,
      email,
      phone,
      [name]: value,
    });
  }

  function handleInput(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    updateData(name, value);
  }

  function handleSlider(name: string) {
    return function (e: any, value: number | number[]) {
      updateData(
        name,
        (
          ((value as number) / 100) *
          (name === "entryValue" ? getCurrencyNumber("value") : 150000)
        )
          .toFixed(2)
          .toString()
          .replace(".", ",")
      );
    };
  }

  function handleCurrency(key: string) {
    return function (value: { formattedValue: string; value: string }) {
      updateData(key, value.formattedValue);
    };
  }

  function getCurrencyNumber(key: "value" | "entryValue") {
    return Number.parseFloat(
      data[key]?.replace("R$ ", "").replace(".", "").replace(",", ".") || "0"
    );
  }
  function valuetext(value: number) {
    return `${Number.isNaN(value) ? 0 : value}%`;
  }

  function checkErrors() {
    let tempErrors: IErrorType = {};
    if (!data.year) tempErrors = { ...tempErrors, year: "Campo obrigatório" };
    if (!data.name) tempErrors = { ...tempErrors, name: "Campo obrigatório" };
    if (!data.cpf) tempErrors = { ...tempErrors, cpf: "Campo obrigatório" };
    if (!data.cpf.match(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/))
      tempErrors = { ...tempErrors, cpf: "Por favor, informe um CPF válido" };
    if (!data.uf) tempErrors = { ...tempErrors, uf: "Campo obrigatório" };
    if (!data.phone) tempErrors = { ...tempErrors, phone: "Campo obrigatório" };
    if (!data.phone.match(/^[(]\d{2}[)] \d{5}-(\d{4}|\d{3})/))
      tempErrors = {
        ...tempErrors,
        phone: "Por favor, informe um número válido",
      };
    if (!data.email) tempErrors = { ...tempErrors, email: "Campo obrigatório" };
    if (
      !data.email.match(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
      )
    )
      tempErrors = {
        ...tempErrors,
        email: "Por favor, informe um e-mail válido",
      };
    if (!data.value) tempErrors = { ...tempErrors, value: "Campo obrigatório" };
    if (!data.entryValue)
      tempErrors = { ...tempErrors, entryValue: "Campo obrigatório" };
    if (!data.model && knowCar)
      tempErrors = { ...tempErrors, model: "Campo obrigatório" };
    if (!data.brand && knowCar)
      tempErrors = { ...tempErrors, brand: "Campo obrigatório" };
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length > 0) return false;
    return true;
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const formOk = checkErrors();
    console.log(data.value);
    if (formOk) {
      history.push("/resultados", data);
    }
  }

  function handleDate(value: MaterialUiPickersDate) {
    updateData("birthday", value ? value?.toString() : "");
  }

  return (
    <div id="page-form" className="container">
      <div className="topbar">
        <div className="logo-container">
          <Link to="/">
            <img src={logoImg} alt="Parcela Inteligente" />
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div id="car-data">
          {pathname.includes("/vender") ? (
            <div className="dealer-bar">Código CoBar {user.seller_code}</div>
          ) : (
            <nav>
              <ul className="tabs">
                <li
                  className={`tab ${knowCar && "active"}`}
                  onClick={handleKnowCarClick}
                >
                  Já sei o carro
                </li>
                <li
                  className={`tab ${!knowCar && "active"}`}
                  onClick={handleDontKnowCarClick}
                >
                  Não sei o carro
                </li>
              </ul>
            </nav>
          )}
          <div className="car-data-form">
            {knowCar ? (
              <>
                <Autocomplete
                  options={brands}
                  getOptionLabel={(option) => option.nome}
                  onChange={(e, value) => {
                    setSelectedBrand(value);
                  }}
                  getOptionSelected={(option, value) =>
                    option && value && option.nome === value.nome
                  }
                  value={selectedBrand}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marca"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalOfferOutlined />
                          </InputAdornment>
                        ),
                      }}
                      helperText={errors?.brand}
                    />
                  )}
                />
                <Autocomplete
                  options={models}
                  getOptionLabel={(option) => option.nome}
                  onChange={(e, value) => {
                    setSelectedModel(value);
                  }}
                  getOptionSelected={(option, value) =>
                    option && value && option.nome === value.nome
                  }
                  value={selectedModel}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Modelo"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <DirectionsCarOutlined />
                          </InputAdornment>
                        ),
                      }}
                      helperText={errors?.model}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  select
                  name="year"
                  value={data?.year}
                  onChange={handleInput}
                  label="Ano"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayOutlined />
                      </InputAdornment>
                    ),
                  }}
                  helperText={errors?.year}
                >
                  {years.map((year) => (
                    <MenuItem value={year.nome}>{year.nome}</MenuItem>
                  ))}
                </TextField>
              </>
            ) : (
              <TextField
                fullWidth
                name="year"
                value={data?.year}
                onChange={handleInput}
                label="Ano"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayOutlined />
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.year}
              />
            )}
            <div className="value-group">
              <FormLabel>
                <MonetizationOnOutlined /> Valor do veículo
              </FormLabel>
              <CurrencyInput
                className="value-field"
                id="value-input"
                type="text"
                name="value"
                thousandSeparator="."
                decimalSeparator=","
                value={data?.value}
                onValueChange={handleCurrency("value")}
                separator=","
                pattern={undefined}
                prefix="R$ "
              />
              {errors.value && <FormHelperText>{errors.value}</FormHelperText>}
            </div>
            <Slider
              step={0.1}
              value={(getCurrencyNumber("value") / 150000) * 100}
              onChange={handleSlider("value")}
            />
            <div className="value-group">
              <FormLabel>
                <PlayCircleOutlineOutlined /> Entrada
              </FormLabel>
              <CurrencyInput
                className="value-field"
                id="value-input"
                type="text"
                name="entryValue"
                thousandSeparator="."
                decimalSeparator=","
                value={data?.entryValue}
                onValueChange={handleCurrency("entryValue")}
                separator=","
                pattern={undefined}
                prefix="R$ "
              />
              {errors.entryValue && (
                <FormHelperText>{errors.entryValue}</FormHelperText>
              )}
            </div>
            <Slider
              value={Math.round(
                (getCurrencyNumber("entryValue") / getCurrencyNumber("value")) *
                  100
              )}
              onChange={handleSlider("entryValue")}
              valueLabelDisplay="on"
              valueLabelFormat={valuetext}
            />
          </div>
        </div>
        <div className="user-data">
          <h2>Dados do Comprador</h2>
          <TextField
            fullWidth
            name="name"
            value={data?.name}
            onChange={handleInput}
            label="Nome"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityOutlined />
                </InputAdornment>
              ),
            }}
            helperText={errors?.name}
          />
          <TextField
            fullWidth
            name="email"
            value={data?.email}
            onChange={handleInput}
            label="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
            helperText={errors?.email}
          />
          <div className="form-row">
            <ReactInputMask
              value={data?.phone}
              onChange={handleInput}
              mask="(99) 99999-9999"
            >
              {() => (
                <TextField
                  name="phone"
                  onChange={handleInput}
                  label="Celular"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroidOutlined />
                      </InputAdornment>
                    ),
                  }}
                  helperText={errors?.phone}
                />
              )}
            </ReactInputMask>
            <TextField
              name="uf"
              value={data?.uf}
              onChange={handleInput}
              label="UF"
              select
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
              helperText={errors?.uf}
            >
              {UFList.map((uf: string) => (
                <MenuItem key={uf} value={uf}>
                  {uf}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="form-row">
            <ReactInputMask
              value={data?.cpf}
              onChange={handleInput}
              mask="999.999.999-99"
            >
              {() => (
                <TextField
                  fullWidth
                  name="cpf"
                  value={data?.cpf}
                  onChange={handleInput}
                  label="CPF"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentOutlined />
                      </InputAdornment>
                    ),
                  }}
                  helperText={errors?.cpf}
                />
              )}
            </ReactInputMask>
            <MuiPickersUtilsProvider utils={DateMomentUtils}>
              <KeyboardDatePicker
                autoOk
                name="birthday"
                value={data?.birthday}
                onChange={handleDate}
                label="Data de Nascimento"
                format="DD/MM/yyyy"
                InputAdornmentProps={{ position: "start" }}
                disableFuture
                invalidDateMessage="Data inválida"
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="button-container">
          <button type="submit" id="search-button">
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Form;
