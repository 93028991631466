import React from "react";
import { TextField, InputAdornment, MenuItem } from "@material-ui/core";
import {
  PermIdentityOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
  PlaceOutlined,
  AssignmentOutlined,
} from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";
import CurrencyInput from "react-currency-format";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import WcOutlinedIcon from "@material-ui/icons/WcOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import Filter1OutlinedIcon from "@material-ui/icons/Filter1Outlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import LocalGasStationOutlinedIcon from "@material-ui/icons/LocalGasStationOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import { GrUserWorker } from "react-icons/gr";
import { IoLogoModelS } from "react-icons/io";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";
import { BsCardHeading } from "react-icons/bs";
import { VscMilestone } from "react-icons/vsc";
import ReactInputMask from "react-input-mask";
import { UFList } from "../../config/UFList";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import DateMomentUtils from "@date-io/moment";
import Crop32OutlinedIcon from "@material-ui/icons/Crop32Outlined";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IStepComponent } from "../../interfaces";
import moment from "moment";
import { useAuth } from "../../hooks/auth";
import { RequestService } from "../../services";
import { useHistory } from "react-router-dom";

function CarData({ setComponent, setData, data }: IStepComponent) {
  const history = useHistory();
  const { user } = useAuth();
  function handleDate(field: string) {
    function setDate(value: MaterialUiPickersDate) {
      setData(field, value ? value?.toString() : "");
    }
    return setDate;
  }

  function handleInput(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      const response = await RequestService.sendRequest(
        data,
        data.bank,
        user.seller_code
      );
      if (response.ok && response.data.success) {
        history.push("/vendedor");
      } else throw response.error;
    } catch (e) {}
  }

  return (
    <div id="carData">
      <form onSubmit={handleSubmit}>
        <h1>Dados do Veículo</h1>
        <h3>Dados Gerais</h3>
        <div className="form-row">
          <TextField
            fullWidth
            name="fabricationYear"
            label="Ano de fabricação"
            value={data.fabricationYear}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="modelYear"
            label="Ano do modelo"
            value={data.modelYear}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            name="carBrand"
            label="Marca do veículo"
            value={data.carBrand}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalOfferOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="carModel"
            label="Modelo do veículo"
            value={data.carModel}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IoLogoModelS />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            name="carVersion"
            label="Versão do veículo"
            value={data.carVersion}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsLayoutTextSidebarReverse />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="plate"
            label="Placa"
            value={data.plate}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsCardHeading />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            name="plateCurrentUF"
            label="UF ou Estado atual da Placa do Carro"
            value={data.plateCurrentUF}
            onChange={handleInput}
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlined />
                </InputAdornment>
              ),
            }}
          >
            {UFList.map((uf: string) => (
              <MenuItem key={uf} value={uf}>
                {uf}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            name="plateFutureUF"
            label="Estado ou UF onde vai licenciar o veiculo"
            value={data.plateFutureUF}
            onChange={handleInput}
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlined />
                </InputAdornment>
              ),
            }}
          >
            {UFList.map((uf: string) => (
              <MenuItem key={uf} value={uf}>
                {uf}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            name="mileage"
            label="Quilometragem"
            value={data.mileage}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VscMilestone />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="fuel"
            label="Combustível"
            value={data.fuel}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalGasStationOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            name="carColor"
            label="Cor do veículo"
            value={data.carColor}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ColorLensOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="RENAVAM"
            label="RENAVAM"
            value={data.RENAVAM}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsLayoutTextSidebarReverse />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TextField
          fullWidth
          name="CHASSI"
          label="CHASSI"
          value={data.CHASSI}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsLayoutTextSidebarReverse />
              </InputAdornment>
            ),
          }}
        />
        {/* <TextField
          fullWidth
          name="observations"
          label="Observações"
          value={data.observations}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BsLayoutTextSidebarReverse />
              </InputAdornment>
            ),
          }}
        /> */}
        <h3>Dados da Compra</h3>
        <div className="form-row">
          <TextField
            fullWidth
            name="carPrice"
            label="Valor do veículo"
            value={data.carPrice}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>
              ),
              inputProps: {
                thousandSeparator: ".",
                decimalSeparator: ",",
                prefix: "R$ ",
              },
              inputComponent: CurrencyInput,
            }}
          />
          <TextField
            fullWidth
            name="downPayment"
            label="Valor da entrada"
            helperText="Recomendado: 25-30%"
            value={data.downPayment}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>
              ),
              inputProps: {
                thousandSeparator: ".",
                decimalSeparator: ",",
                prefix: "R$ ",
              },
              inputComponent: CurrencyInput,
            }}
          />
        </div>
        <TextField
          name="seller"
          label="De quem vai comprar o veículo"
          fullWidth
          select
          value={data.seller}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StoreOutlinedIcon />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="loja">Loja</MenuItem>
          <MenuItem value="concessionaria">Concessionária</MenuItem>
          <MenuItem value="pessoaFisica">Pessoa Física</MenuItem>
        </TextField>

        <TextField
          fullWidth
          name="taxTable"
          label="Tabela de taxas"
          value={data.taxTable}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TableChartOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          name="installments"
          label="Número de parcelas"
          value={data.installments}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Filter1OutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <KeyboardDatePicker
            fullWidth
            autoOk
            name="bestDayForPayment"
            onChange={handleDate("bestDayForPayment")}
            value={data.bestDayForPayment}
            label="Melhor dia para pagamento"
            format="DD/MM/yyyy"
            InputAdornmentProps={{ position: "start" }}
            minDate={new Date()}
            maxDate={moment().add(45, "days")}
            invalidDateMessage="Data inválida"
          />
        </MuiPickersUtilsProvider>

        <div className="button">
          <button
            className="prev"
            type="button"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setComponent("ClientData");
            }}
          >
            <KeyboardBackspaceIcon /> <span>Voltar</span>
          </button>
          <button type="submit" onClick={() => setComponent("CarData")}>
            <span>Enviar</span> <SendIcon />
          </button>
        </div>
      </form>
    </div>
  );
}

export default CarData;
