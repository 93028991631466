import React from "react";
import { IStepComponent } from "../../interfaces";

function Step3({ setComponent }: IStepComponent) {
  return (
    <div className="container">
      <div className="label">
        <div className="validation">
          A VALIDAÇÃO DOS DUCUMENTOS AINDA NÃO FOI FINALIZADA. CASO VOCÊ JÁ
          TENHA CONCLUÍDO O PROCESSO DE ENVIO DAS FOTOS DO DOCUMENTO E DA SUA
          PRÓPRIA FOTO, FAVOR AGUARDAR ALGUNS INSTANTES. CASO VOCÊ NÃO TENHA
          FINALIZADO, FAVOR CLICAR NO BOTÃO ABAIXO PARA ENVIAR OS DOCUMENTOS.
        </div>
        <div className="come-back">
          Após terminar a validação, retorne a esta página para continuar o
          cadastro.
        </div>
      </div>
      <div className="button">
        <button onClick={() => setComponent("Step2")}>
          Reenviar documentos
        </button>
      </div>
    </div>
  );
}

export default Step3;
