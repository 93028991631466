import React from "react";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import "./style.css";
import DetailForm from "../../components/DetailForm";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link, useParams, useHistory } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useAuth } from "../../hooks/auth";
import { IProposal } from "../../interfaces";
import { RequestService } from "../../services";

function SellerDetais() {
  const { cpf, dateTime } = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const [proposal, setProposal] = React.useState<IProposal | null>(null);

  React.useEffect(() => {
    if (user && cpf && dateTime) {
      if (!user.seller_code) history.replace("/backoffice");
      else
        RequestService.get(cpf, dateTime, user.seller_code).then((response) =>
          setProposal(response.data.result)
        );
    }
  }, [cpf, dateTime, user]);

  return (
    <div id="sellerDetaisPage" className="container">
      <div className="head">
        <h1>Detalhe do Vendedor</h1>
      </div>
      <DetailForm proposal={proposal} />
      <div className="buttons">
        <Link to="/vendedor" className="prev">
          <KeyboardBackspaceIcon /> <span>Voltar</span>
        </Link>
        {/* <Link to="/vendedor" className="edit">
          <EditOutlinedIcon /> <span>Editar</span>
        </Link> */}
      </div>
    </div>
  );
}

export default SellerDetais;
