export const fetchWrapper = async (route: string, options?: any) => {
  try {
    const result = await fetch(route, options);
    if (result.status === 200 || result.status === 204) {
      return { ok: true, data: await result.json() };
    } else {
      throw result;
    }
  } catch (error) {
    return { ok: false, error };
  }
};

export { default as FipeService } from "./fipe";
export { default as SimulationService } from "./Simulation";
export { default as UserService } from "./User";
export { default as RequestService } from "./Request";
export { default as CEPService } from "./CEP";
