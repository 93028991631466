import React from "react";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import {
  AssignmentOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
  PermIdentityOutlined,
} from "@material-ui/icons/";

import "./style.css";
import { IStepComponent } from "../../interfaces";

function Step4({ setComponent, data, setData }: IStepComponent) {
  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }
  return (
    <div id="step4" className="container">
      <div className="label">
        <div className="validation">POR FAVOR CONFIRME OS DADOS ABAIXO</div>
        <div className="come-back">
          Referentes ao sócio/proprietário/responsável pela loja
        </div>
      </div>
      <div className="text-field">
        <TextField
          fullWidth
          label="Nome"
          disabled
          name="sellerName"
          value={data.sellerName}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityOutlined />
              </InputAdornment>
            ),
          }}
        />
        <ReactInputMask
          value={data.sellerCPF}
          onChange={handleChange}
          disabled
          mask="999.999.999-99"
        >
          {() => (
            <TextField
              fullWidth
              name="sellerCPF"
              disabled
              label="CPF"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AssignmentOutlined />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </ReactInputMask>
        <TextField
          fullWidth
          label="E-mail"
          // disabled
          name="email"
          value={data.email}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
        <ReactInputMask
          disabled
          value={data.mobile_number}
          onChange={handleChange}
          mask="(99) 99999-9999"
        >
          {() => (
            <TextField
              fullWidth
              name="mobile_number"
              disabled
              label="Celular"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneAndroidOutlined />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </ReactInputMask>
      </div>
      <div className="button">
        <button onClick={() => setComponent("Step5")}>Confirmar</button>
      </div>
    </div>
  );
}

export default Step4;
