import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { FiFacebook } from "react-icons/fi";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";

import "./style.css";
import { IStepComponent } from "../../interfaces";
import { KeyboardBackspace, Send } from "@material-ui/icons";

function Step8({ setComponent, setData, data }: IStepComponent) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target as HTMLInputElement;
    const banks = data.partnershipBanks ? [...data.partnershipBanks] : [];
    if (checked) banks.push(value);
    else {
      banks.splice(banks.indexOf(value), 1);
    }
    setData("partnershipBanks", banks);
  };

  return (
    <div id="step8" className="container">
      <div className="label">
        <div className="validation">
          COM QUAIS BANCOS VOCÊ TEM CADASTRO COMO CORRESPONDENTE BANCÁRIO PARA
          REALIZAR FINANCIAMENTOS?
        </div>
      </div>
      <div className="checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Itaú")}
              onChange={handleChange}
              name="Itaú"
              value="Itaú"
              color="primary"
            />
          }
          label="Itaú"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Banco do Brasil")}
              onChange={handleChange}
              name="Banco do Brasil"
              value="Banco do Brasil"
              color="primary"
            />
          }
          label="Banco do Brasil"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Porto Seguro")}
              onChange={handleChange}
              name="Porto Seguro"
              value="Porto Seguro"
              color="primary"
            />
          }
          label="Porto Seguro"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Bradesco")}
              onChange={handleChange}
              name="Bradesco"
              value="Bradesco"
              color="primary"
            />
          }
          label="Bradesco"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("BV")}
              onChange={handleChange}
              name="BV"
              value="BV"
              color="primary"
            />
          }
          label="BV"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Omni")}
              onChange={handleChange}
              name="Omni"
              value="Omni"
              color="primary"
            />
          }
          label="Omni"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Santander")}
              onChange={handleChange}
              name="Santander"
              value="Santander"
              color="primary"
            />
          }
          label="Santander"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={data.partnershipBanks?.includes("Banco Pan")}
              onChange={handleChange}
              name="Banco Pan"
              value="Banco Pan"
              color="primary"
            />
          }
          label="Banco Pan"
        />
      </div>
      <div className="button">
        <button
          className="prev"
          type="button"
          onClick={() => {
            setComponent("Step7");
          }}
        >
          <KeyboardBackspace /> <span>Voltar</span>
        </button>
        <button type="submit" onClick={() => setComponent("Step9")}>
          <span>Continuar</span> <Send />
        </button>
      </div>
    </div>
  );
}

export default Step8;
