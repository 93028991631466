import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import "./style.css";

import ClientsData from "../../components/ClientsData";
import CarData from "../../components/CarData";
import { IRequestType, IStepComponent } from "../../interfaces";

interface ICompProps extends IStepComponent {
  component: string;
}
function Component({ component, ...rest }: ICompProps) {
  switch (component) {
    case "CarData":
      return <CarData {...rest} />;
    case "ClientData":
    default:
      return <ClientsData {...rest} />;
  }
}

const initialState = {
  name: "",
  cpf: "",
  birthday: null,
  phone: "",
  residencialPhone: "",
  email: "",
  gender: "",
  mothersName: "",
  fathersName: "",
  rg: "",
  emissionDate: null,
  emissionAgency: "",
  documentUF: "",
  nationality: "",
  naturalness: "",
  maritalStatus: "",
  spousesName: "",
  spousesCpf: "",
  patrimonyTotalValue: "",
  ocupationDetail: "",
  natureOfOcupation: "",
  ocupationTime: "",
  totalMonthlyIncome: "",
  comercialPhone: "",
  cep: "",
  propertySituation: "",
  neighborhood: "",
  street: "",
  number: "",
  complement: "",
  city: "",
  uf: "",
  reference: "",
  referencePhone: "",
  fabricationYear: "",
  modelYear: "",
  carBrand: "",
  carModel: "",
  carVersion: "",
  plate: "",
  plateCurrentUF: "",
  plateFutureUF: "",
  mileage: "",
  fuel: "",
  carColor: "",
  RENAVAM: "",
  CHASSI: "",
  carPrice: "",
  downPayment: "",
  seller: "",
  taxTable: "",
  installments: "",
  bestDayForPayment: null,
  observations: "",
};

interface IState {
  brand: string;
  model: string;
  year: string;
  entryValue: string;
  value: string;
  name: string;
  cpf: string;
  birthday: string;
  phone: string;
  email: string;
  installments: string;
  bank: string;
}

type IndexProps = RouteComponentProps<{}, {}, IState>;

const RequestForm: React.FC<IndexProps> = ({ location: { state } }) => {
  const [component, setComponent] = React.useState<string>("ClientData");
  const [data, setData] = React.useState<IRequestType>(initialState);

  React.useEffect(() => {
    setData((curState) => ({
      ...curState,
      carBrand: state?.brand,
      carModel: state?.model,
      fabricationYear: state?.year,
      modelYear: state?.year,
      downPayment: state?.entryValue,
      carPrice: state?.value,
      name: state?.name,
      cpf: state?.cpf,
      birthday: state?.birthday,
      phone: state?.phone,
      email: state?.email,
      installments: state?.installments,
      bank: state?.bank,
    }));
  }, [state]);

  function updateData(field: string, value: string) {
    setData((state) => ({
      ...state,
      [field]: value,
    }));
  }

  return (
    <div id="requestForm" className="container">
      <Component
        component={component}
        setComponent={setComponent}
        setData={updateData}
        data={data}
      />
    </div>
  );
};

export default withRouter(RequestForm);
