import React from "react";
import { AiOutlineBank, AiFillBank } from "react-icons/ai";
import { FaPiggyBank } from "react-icons/fa";
import { MdLabelOutline } from "react-icons/md";
import ReactInputMask from "react-input-mask";
import passwordIcon from "../../assets/img/icons/password.png";
import ClosedEyeIcon from "@material-ui/icons/VisibilityOffOutlined";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";

import "./style.css";
import { IStepComponent } from "../../interfaces";
import { UserService } from "../../services";
import { useToast } from "../../hooks/toast";
import { KeyboardBackspace, Send } from "@material-ui/icons";

function Step10({ setComponent, data, setData }: IStepComponent) {
  const [hiddenPassword, setHiddenPassword] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const { addToast } = useToast();
  function toggleVisibility() {
    setHiddenPassword(!hiddenPassword);
  }

  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (data.password === data.passwordConfirmation)
      UserService.signUp(data)
        .then((response) => {
          if (response.data.success) {
            setComponent("Step11");
          } else {
            addToast({
              title: "Erro",
              description: response.data.message,
              type: "error",
            });
          }
        })
        .catch((e) =>
          addToast({
            title: "Erro",
            description: e.message,
            type: "error",
          })
        );
    else setError(true);
  }

  return (
    <div id="step10" className="container">
      <div className="label">
        <div className="validation">CRIE SUA SENHA</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="text-field">
          <TextField
            fullWidth
            type={hiddenPassword ? "password" : "text"}
            label="Senha"
            name="password"
            value={data.password}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordIcon}
                    alt="Ícone de senha"
                    className="icon"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <span className="passwordButton" onClick={toggleVisibility}>
                    {hiddenPassword ? <EyeIcon /> : <ClosedEyeIcon />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="passwordConfirmation"
            value={data.passwordConfirmation}
            onChange={handleChange}
            type={hiddenPassword ? "password" : "text"}
            label="Confirmação de senha"
            helperText={
              error
                ? "Senha e confirmação de senha devem ser idênticas"
                : undefined
            }
            FormHelperTextProps={{ style: { color: "#F33", fontSize: "1rem" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={passwordIcon}
                    alt="Ícone de senha"
                    className="icon"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <span className="passwordButton" onClick={toggleVisibility}>
                    {hiddenPassword ? <EyeIcon /> : <ClosedEyeIcon />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="button">
          <button
            className="prev"
            type="button"
            onClick={() => {
              setComponent("Step9");
            }}
          >
            <KeyboardBackspace /> <span>Voltar</span>
          </button>
          <button type="submit">
            <span>Enviar Cadastro</span> <Send />
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step10;
