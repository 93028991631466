import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";
import { FiFacebook } from "react-icons/fi";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import { IStepComponent } from "../../interfaces";
import { KeyboardBackspace, Send } from "@material-ui/icons";

function Step7({ setComponent, data, setData }: IStepComponent) {
  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }
  return (
    <div className="container">
      <div className="label">
        <div className="validation">INFORME AS REDES SOCIAIS DA LOJA</div>
        <div className="come-back">(Opcional)</div>
      </div>
      <div className="text-field">
        <TextField
          fullWidth
          label="Facebook"
          name="facebook"
          value={data.facebook}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FiFacebook />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Instagram"
          name="instagram"
          value={data.instagram}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineInstagram />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Site"
          name="site"
          value={data.site}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* <TextField
          fullWidth
          label="Outro"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MoreHorizOutlinedIcon />
              </InputAdornment>
            ),
          }}
        /> */}
      </div>
      <div className="button">
        <button
          className="prev"
          type="button"
          onClick={() => {
            setComponent("Step6");
          }}
        >
          <KeyboardBackspace /> <span>Voltar</span>
        </button>
        <button type="submit" onClick={() => setComponent("Step8")}>
          <span>Continuar</span> <Send />
        </button>
      </div>
    </div>
  );
}

export default Step7;
