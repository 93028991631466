import React, { createContext, useCallback, useState, useContext } from "react";
import { UserService } from "../services";

interface Credentials {
  email: string;
  password: string;
}

interface User {
  shopName: string;
  sellerName: string;
  mobile_areaCode: string;
  mobile_number: string;
  seller_code: string;
}
interface AuthContextData {
  user: User;
  signed: boolean;
  signIn(credentials: Credentials): Promise<SignInConfirmation>;
  signOut(): void;
}

interface AuthState {
  user: User;
}

interface SignInConfirmation {
  ok: boolean;
  message?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem("parcela@user");

    if (user) {
      return { user: JSON.parse(user) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const response = await UserService.signIn({ email, password });
      if (response.ok && response.data?.success) {
        const {
          shopName,
          sellerName,
          mobile_areaCode,
          mobile_number,
          seller_code,
        } = response.data;
        localStorage.setItem(
          "parcela@user",
          JSON.stringify({
            shopName,
            sellerName,
            mobile_areaCode,
            mobile_number,
            seller_code,
          })
        );
        setData({
          user: {
            shopName,
            sellerName,
            mobile_areaCode,
            mobile_number,
            seller_code,
          },
        });
        return { ok: true };
      } else {
        throw response.data.message || response;
      }
    } catch (e) {
      return { ok: false, message: e.message || e };
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("parcela@user");

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signed: !!data.user, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
