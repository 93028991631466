import React from "react";

import "./style.css";

function Loading() {
  return (
    <div id="loading-page" className="container">
      <div className="animation">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className="selecting">Estamos selecionando</div>
      <div className="options">AS MELHORES OPÇÕES PARA VOCÊ</div>
    </div>
  );
}

export default Loading;
