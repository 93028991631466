import React from "react";

import "./style.css";
import Step1 from "../../components/Step1";
import Step2 from "../../components/Step2";
import Step3 from "../../components/Step3";
import Step4 from "../../components/Step4";
import Step5 from "../../components/Step5";
import Step6 from "../../components/Step6";
import Step7 from "../../components/Step7";
import Step8 from "../../components/Step8";
import Step9 from "../../components/Step9";
import Step10 from "../../components/Step10";
import Step11 from "../../components/Step11";
import ApprovedCPF from "../../components/ApprovedCPF";
import DeniedCPF from "../../components/DeniedCPF";
import { IStepComponent } from "../../interfaces";

interface IProps extends IStepComponent {
  component: string;
}

function Component({ component, ...rest }: IProps) {
  switch (component) {
    case "Step1":
      return <Step1 {...rest} />;
    case "Step2":
      return <Step2 {...rest} />;
    case "Step3":
      return <Step3 {...rest} />;
    case "Step4":
      return <Step4 {...rest} />;
    case "Step5":
      return <Step5 {...rest} />;
    case "Step6":
      return <Step6 {...rest} />;
    case "Step7":
      return <Step7 {...rest} />;
    case "Step8":
      return <Step8 {...rest} />;
    case "Step9":
      return <Step9 {...rest} />;
    case "Step10":
      return <Step10 {...rest} />;
    case "Step11":
      return <Step11 {...rest} />;
    case "ApprovedCPF":
      return <ApprovedCPF {...rest} />;
    case "DeniedCPF":
      return <DeniedCPF {...rest} />;
    default:
      return <Step1 {...rest} />;
  }
}

function SignUp() {
  const [component, setComponent] = React.useState<string>("Step1");
  const [data, setData] = React.useState<any>({});

  function handleData(name: string, value: string) {
    setData((state: any) => ({ ...state, [name]: value }));
  }

  return (
    <div id="signup-page" className="container">
      <Component
        component={component}
        setComponent={setComponent}
        setData={handleData}
        data={data}
      />
    </div>
  );
}

export default SignUp;
