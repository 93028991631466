import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ResultCard from "../../components/ResultCard";
import Loading from "../../components/Loading";
import "./style.css";
import logo from "../../assets/img/logo.png";
import { SimulationService } from "../../services";
import { IFormType } from "../../interfaces";
import { useAuth } from "../../hooks/auth";

function Results() {
  const [loadingResults, setLoadingResults] = React.useState<boolean>(true);
  const { state } = useLocation();
  const [results, setResults] = React.useState<any>([]);
  const { user, signed } = useAuth();
  const history = useHistory();
  const [installmentSelected, setInstallmentSelected] = React.useState<string>(
    ""
  );
  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(
    false
  );
  const initial: string[] = [];
  const [selectedOption, setSelectedOption] = React.useState<{
    bank: string;
    installments: string;
  }>({ bank: "", installments: "" });

  React.useEffect(() => {
    async function request() {
      try {
        const response = await SimulationService.get(state as IFormType);
        if (response.ok) {
          setResults(response.data);
        } else throw response;
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingResults(false);
      }
    }
    if (!state) history.replace("/");
    request();
  }, [state]);

  React.useEffect(() => {
    setInstallmentSelected(getOptions()[0]);
  }, [results]);

  function getOptions() {
    const options = results?.reduce((arr: Array<any>, bank: any) => {
      const newArr: string[] = arr;
      bank.financeOperationDetails?.forEach((option: any) => {
        if (!newArr.includes(option.installments))
          newArr.push(option.installments);
      });
      return newArr;
    }, initial);
    return options?.sort(
      (a: string, b: string) => Number.parseInt(b) - Number.parseInt(a)
    );
  }

  function getTotalResults() {
    return results.reduce(
      (count: number, result: any) =>
        result.financeOperationDetails
          ? count + result.financeOperationDetails.length
          : count,
      0
    );
  }

  function handleClose() {
    setOpenConfirmation(false);
  }

  function handleConfirmation() {
    handleClose();
    history.push({
      pathname: "/solicitacao",
      state: { ...state, ...selectedOption },
    });
  }

  function handleSelection(bank: string, installments: string) {
    if (!signed) return () => {};
    function select() {
      setOpenConfirmation(true);
      setSelectedOption({ bank, installments });
    }
    return select;
  }

  if (loadingResults) {
    return <Loading />;
  }
  return (
    <div id="results" className="container">
      <div className="topbar">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Parcela Inteligente" />
          </Link>
        </div>
      </div>
      {signed && (
        <div className="dealer-bar">Código CoBar {user.seller_code}</div>
      )}
      <div className="summary">
        <div className="result-count">
          <div>
            <span id="number">{getTotalResults()}</span>
            <span id="result-label">RESULTADOS</span>
            <span id="for-search">PARA SUA BUSCA</span>
          </div>
        </div>
        <div className="values">
          <div>
            <span className="summary-label">Valor do Veículo</span>
            <span className="car-value">
              R${" "}
              <span className="number">
                {Number.parseFloat(
                  ((state as IFormType)?.value || "0")
                    ?.replace("R$", "")
                    .replace(".", "")
                    .replace(",", ".")
                )
                  .toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                  .replace("R$", "")}
              </span>
            </span>
            <span className="summary-label">Valor de Entrada</span>
            <span className="entry-value">
              {Number.parseFloat(
                ((state as IFormType)?.entryValue || "0")
                  ?.replace("R$", "")
                  .replace(".", "")
                  .replace(",", ".")
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="installments-bar">
        {getOptions().map((option: string) => (
          <span
            key={option}
            className={`installments-item ${
              installmentSelected === option ? "active" : ""
            }`}
            onClick={() => setInstallmentSelected(option)}
          >
            {option}x
          </span>
        ))}
      </div>
      <div className="resultsContainer">
        {results.map((bank: any) => (
          <ResultCard
            financial={bank.financial}
            logo={bank.logo}
            financeOperationDetails={bank.financeOperationDetails?.find(
              (operation: any) => operation.installments === installmentSelected
            )}
            onClick={handleSelection(bank.financial, installmentSelected)}
            buying={!signed}
          />
        ))}
      </div>
      <Dialog
        open={openConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja dar inicio à solicitação para a oferta selecionada?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmation} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Results;
