import React from "react";
import { AiOutlineBank, AiFillBank } from "react-icons/ai";
import { FaPiggyBank } from "react-icons/fa";
import { MdLabelOutline } from "react-icons/md";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";

import "./style.css";
import { IStepComponent } from "../../interfaces";
import { KeyboardBackspace, Send } from "@material-ui/icons";

function Step9({ setComponent, data, setData }: IStepComponent) {
  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }
  return (
    <div id="step9" className="container">
      <div className="label">
        <div className="validation">
          INFORME OS DADOS BANCÁRIOS PARA PAGAMENTO DA COMISSÃO
        </div>
        <div className="come-back">(Opcional)</div>
      </div>
      <div className="text-field">
        <TextField
          fullWidth
          label="Banco"
          name="bank"
          value={data.bank}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineBank />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Nome da conta"
          name="accountName"
          value={data.accountName}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdLabelOutline />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Conta"
          name="account"
          value={data.account}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaPiggyBank />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          label="Agência"
          name="branch"
          value={data.branch}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiFillBank />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="button">
        <button
          className="prev"
          type="button"
          onClick={() => {
            setComponent("Step8");
          }}
        >
          <KeyboardBackspace /> <span>Voltar</span>
        </button>
        <button type="submit" onClick={() => setComponent("Step10")}>
          <span>Continuar</span> <Send />
        </button>
      </div>
    </div>
  );
}

export default Step9;
