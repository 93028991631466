import React from "react";
import { TextField, InputAdornment, MenuItem } from "@material-ui/core";
import {
  PermIdentityOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
  PlaceOutlined,
  AssignmentOutlined,
} from "@material-ui/icons";
import { VscMilestone } from "react-icons/vsc";
import CurrencyInput from "react-currency-format";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import Filter1OutlinedIcon from "@material-ui/icons/Filter1Outlined";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { BsCardHeading } from "react-icons/bs";
import LocalGasStationOutlinedIcon from "@material-ui/icons/LocalGasStationOutlined";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import { BiHourglass } from "react-icons/bi";

import Crop32OutlinedIcon from "@material-ui/icons/Crop32Outlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import { IoLogoModelS } from "react-icons/io";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";

import WcOutlinedIcon from "@material-ui/icons/WcOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import EventIcon from "@material-ui/icons/Event";
import ReactInputMask from "react-input-mask";
import moment from "moment";
import { UFList } from "../../config/UFList";

import "./style.css";
import { IProposal } from "../../interfaces";

function DetailForm({ proposal }: { proposal: IProposal | null }) {
  if (!proposal) return null;
  else
    return (
      <div id="detailForm" className="container">
        <form>
          <h3>Dados Gerais do cliente</h3>
          <TextField
            disabled
            value={proposal.name}
            fullWidth
            name="name"
            label="Nome Completo"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityOutlined />
                </InputAdornment>
              ),
            }}
          />
          <div className="form-row">
            <ReactInputMask disabled value={proposal.cpf} mask="999.999.999-99">
              {() => (
                <TextField
                  disabled
                  fullWidth
                  name="cpf"
                  label="CPF"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
            <TextField
              disabled
              value={moment(proposal.birthDate).format("DD/MM/YYYY")}
              fullWidth
              name="birthday"
              label="Data de nascimento"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <ReactInputMask
              disabled
              value={proposal.mobile_areaCode + proposal.mobile_number}
              mask="(99) 99999-9999"
            >
              {() => (
                <TextField
                  disabled
                  name="phone"
                  label="Celular"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroidOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
            <ReactInputMask
              disabled
              value={proposal.phone_areaCode + proposal.phone_number}
              mask="(99) 9999-9999"
            >
              {() => (
                <TextField
                  disabled
                  name="residencialPhone"
                  label="Telefone residencial"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
          </div>
          <TextField
            disabled
            value={proposal.Email}
            fullWidth
            name="email"
            label="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <h3>Dados Complementares</h3>
          <TextField
            disabled
            value={proposal.mother_name}
            fullWidth
            name="mothersName"
            label="Nome da mãe"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            disabled
            value={proposal.father_name}
            fullWidth
            name="fathersName"
            label="Nome do pai"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentOutlined />
                </InputAdornment>
              ),
            }}
          />
          <div className="form-row triple">
            <TextField
              disabled
              value={proposal.gender}
              name="gender"
              label="Sexo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WcOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              disabled
              value={proposal.ID_number}
              fullWidth
              name="rg"
              label="Número do RG/RNE"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AssignmentIndOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={moment(proposal.ID_issue_date).format("DD/MM/YYYY")}
              fullWidth
              name="emissionDate"
              label="Data de emissão"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row triple">
            <TextField
              disabled
              value={proposal.ID_issuing_institution}
              fullWidth
              name="emissionAgency"
              label="Órgão expedidor"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LabelOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.ID_state}
              name="documentUF"
              label="UF do documento"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              disabled
              value={proposal.nationality}
              fullWidth
              name="nationality"
              label="Nacionalidade"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.birth_place}
              fullWidth
              name="naturalness"
              label="Naturalidade"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.marital_status}
              name="maritalStatus"
              label="Estado civil"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Crop32OutlinedIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          <TextField
            disabled
            value={proposal.spouse_name}
            fullWidth
            name="spousesName"
            label="Nome completo do cônjuge"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentOutlined />
                </InputAdornment>
              ),
            }}
          />
          <div className="form-row">
            <ReactInputMask
              disabled
              value={proposal.spouse_cpf}
              mask="999.999.999-99"
            >
              {() => (
                <TextField
                  disabled
                  fullWidth
                  name="spousesCpf"
                  label="CPF do cônjuge"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
            {/* <CurrencyInput
              // className="value-field"
              // id="value-input"
              fullWidth
              disabled
              type="text"
              name="patrimonyTotalValue"
              label="Valor total do patrimônio"
              thousandSeparator="."
              decimalSeparator=","
              value={proposal.assets_value}
              // onValueChange={handleCurrency("patrimonyTotalValue")}
              separator=","
              pattern={undefined}
              prefix="R$ "
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            /> */}
            <TextField
              disabled
              value={proposal.assets_value}
              fullWidth
              name="patrimonyTotalValue"
              label="Valor total do patrimônio"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  thousandSeparator: ".",
                  decimalSeparator: ",",
                  prefix: "R$ ",
                },
                inputComponent: CurrencyInput,
              }}
            />
          </div>

          <div className="form-row">
            <TextField
              disabled
              value={proposal.occupation_nature}
              name="natureOfOcupation"
              label="Natureza da ocupação ou trabalho"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkOutlineOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              disabled
              value={proposal.job_detail}
              fullWidth
              name="ocupationDetail"
              label="Detalhe do trabalho"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkOutlineOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.employed_time}
              fullWidth
              name="ocupationTime"
              label="Tempo de serviço em anos+meses"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BiHourglass />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.total_monthly_income}
              fullWidth
              name="totalMonthlyIncome"
              label="Renda mensal total"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  thousandSeparator: ".",
                  decimalSeparator: ",",
                  prefix: "R$ ",
                },
                inputComponent: CurrencyInput,
              }}
            />
          </div>
          <ReactInputMask
            disabled
            value={
              proposal.commercial_phone_areaCode +
              proposal.commercial_phone_number
            }
            mask="(99) 9999-9999"
          >
            {() => (
              <TextField
                disabled
                name="comercialPhone"
                label="Telefone comercial (opcional)"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
          <h3>Endereço</h3>
          <div className="form-row">
            <ReactInputMask
              disabled
              value={proposal.address_cep}
              mask="99999-999"
            >
              {() => (
                <TextField
                  disabled
                  name="cep"
                  label="CEP"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkunreadMailboxOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
            <TextField
              disabled
              value={proposal.residential_property_situation}
              name="propertySituation"
              label="Situação do imóvel"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>
          <TextField
            disabled
            value={proposal.address_neighborhood}
            fullWidth
            name="neighborhood"
            label="Bairro"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeWorkOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className="form-row">
            <TextField
              disabled
              value={proposal.address_street}
              fullWidth
              name="street"
              label="Rua ou avenida"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.address_number}
              fullWidth
              name="number"
              label="Número"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Filter1OutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <TextField
            disabled
            value={proposal.address_complement}
            fullWidth
            name="complement"
            label="Complemento"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AddLocationOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className="form-row">
            <TextField
              disabled
              value={proposal.address_city}
              fullWidth
              name="city"
              label="Cidade"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.address_uf}
              name="uf"
              label="UF"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          <div className="form-row">
            <TextField
              disabled
              value={proposal.referred_name}
              fullWidth
              name="reference"
              label="Nome de uma referência"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeWorkOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <ReactInputMask
              disabled
              value={
                proposal.referred_phone_areaCode +
                proposal.referred_phone_number
              }
              mask="(99) 9999-9999"
            >
              {() => (
                <TextField
                  disabled
                  name="referencePhone"
                  label="Telefone da referência"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
          </div>
          <h3>Dados do Veículo</h3>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_manufactureYear}
              fullWidth
              name="fabricationYear"
              label="Ano de fabricação"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.car_modelYear}
              fullWidth
              name="modelYear"
              label="Ano do modelo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_manufacturer}
              fullWidth
              name="carBrand"
              label="Marca do veículo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalOfferOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.car_model}
              fullWidth
              name="carModel"
              label="Modelo do veículo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IoLogoModelS />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_version}
              fullWidth
              name="carVersion"
              label="Versão do veículo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsLayoutTextSidebarReverse />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={proposal.car_licence_plate}
              fullWidth
              name="plate"
              label="Placa"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsCardHeading />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_licence_plate_uf}
              fullWidth
              name="plateCurrentUF"
              label="UF ou Estado atual da Placa do Carro"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              disabled
              value={proposal.car_licensing_uf}
              fullWidth
              name="plateFutureUF"
              label="Estado ou UF onde vai licenciar o veiculo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          <TextField
            disabled
            value={proposal.car_mileage}
            fullWidth
            name="mileage"
            label="Quilometragem"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VscMilestone />
                </InputAdornment>
              ),
            }}
          />
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_fuel}
              fullWidth
              name="fuel"
              label="Combustível"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalGasStationOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              disabled
              value={proposal.car_color}
              fullWidth
              name="carColor"
              label="Cor do veículo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ColorLensOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_renavam}
              fullWidth
              name="RENAVAM"
              label="RENAVAM"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsLayoutTextSidebarReverse />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              disabled
              value={proposal.car_chassis}
              fullWidth
              name="CHASSI"
              label="CHASSI"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsLayoutTextSidebarReverse />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <TextField
            disabled
            fullWidth
            name="observations"
            label="Observações"
            value={proposal.observations}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsLayoutTextSidebarReverse />
                </InputAdornment>
              ),
            }}
          /> */}
          <h3>Dados da Compra</h3>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.car_value}
              fullWidth
              name="carPrice"
              label="Valor do veículo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  thousandSeparator: ".",
                  decimalSeparator: ",",
                  prefix: "R$ ",
                },
                inputComponent: CurrencyInput,
              }}
            />
            <TextField
              disabled
              value={proposal.car_entryValue}
              fullWidth
              name="downPayment"
              label="Valor da entrada"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyOutlinedIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  thousandSeparator: ".",
                  decimalSeparator: ",",
                  prefix: "R$ ",
                },
                inputComponent: CurrencyInput,
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.seller_type}
              name="seller"
              label="De quem vai comprar o veículo"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StoreOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>

            <TextField
              disabled
              value={proposal.rate_table}
              fullWidth
              name="taxTable"
              label="Tabela de taxas"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableChartOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-row">
            <TextField
              disabled
              value={proposal.installments_number}
              fullWidth
              name="installments"
              label="Número de parcelas"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Filter1OutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              disabled
              value={moment(proposal.best_payment_day).format("DD/MM/YYYY")}
              fullWidth
              name="bestDayForPayment"
              label="Melhor dia para pagamento"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </form>
      </div>
    );
}

export default DetailForm;
