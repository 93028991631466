import React from "react";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PlaceOutlined from "@material-ui/icons/PlaceOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import Filter1OutlinedIcon from "@material-ui/icons/Filter1Outlined";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import { UFList } from "../../config/UFList";
import MenuItem from "@material-ui/core/MenuItem";

import "./style.css";
import { IStepComponent } from "../../interfaces";
import { KeyboardBackspace, Send } from "@material-ui/icons";

function Step6({ setComponent, data, setData }: IStepComponent) {
  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }
  return (
    <div id="step6" className="container">
      <div className="label">
        <div className="validation">INFORME O ENDEREÇO DA LOJA</div>
      </div>
      <div className="text-field">
        <div className="grid">
          <div className="margin">
            <ReactInputMask
              value={data.address_cep}
              onChange={handleChange}
              mask="99999-999"
            >
              {() => (
                <TextField
                  fullWidth
                  name="address_cep"
                  label="CEP"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MarkunreadMailboxOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </ReactInputMask>
          </div>
          <div className="street">
            <TextField
              fullWidth
              label="Rua ou avenida"
              name="address_street"
              value={data.address_street}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="grid">
          <div className="margin">
            <TextField
              fullWidth
              label="Número"
              name="address_number"
              value={data.address_number}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Filter1OutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <TextField
            fullWidth
            label="Bairro"
            name="address_neighborhood"
            value={data.address_neighborhood}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeWorkOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="grid">
          <div className="margin">
            <TextField
              fullWidth
              label="UF"
              name="address_uf"
              value={data.address_uf}
              onChange={handleChange}
              select
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            >
              {UFList.map((uf: string) => (
                <MenuItem key={uf} value={uf}>
                  {uf}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <TextField
            fullWidth
            label="Cidade"
            name="address_city"
            value={data.address_city}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TextField
          fullWidth
          label="Complemento"
          name="address_complement"
          value={data.address_complement}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddLocationOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="button">
        <button
          className="prev"
          type="button"
          onClick={() => {
            setComponent("Step5");
          }}
        >
          <KeyboardBackspace /> <span>Voltar</span>
        </button>
        <button type="submit" onClick={() => setComponent("Step7")}>
          <span>Continuar</span> <Send />
        </button>
      </div>
    </div>
  );
}

export default Step6;
