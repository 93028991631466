import React from "react";
import { TextField, InputAdornment, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import "./style.css";
import { useAuth } from "../../hooks/auth";
import { RequestService } from "../../services";
import { IProposal } from "../../interfaces";

function Seller() {
  const { user, signOut } = useAuth();
  const history = useHistory();
  const [proposals, setProposals] = React.useState<Array<IProposal>>();
  React.useEffect(() => {
    if (!user.seller_code) history.replace("/backoffice");
    else
      RequestService.list(user.seller_code).then((response) =>
        setProposals(response.data.result)
      );
  }, [user]);

  return (
    <div id="sellerPage" className="container">
      <button className="logout-button" onClick={signOut}>
        Sair
      </button>
      <h1>Vendedor</h1>
      <TableContainer>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="cpf" align="center">
                CPF
              </TableCell>
              <TableCell key="hour" align="center">
                Horário
              </TableCell>
              <TableCell key="model" align="center">
                Modelo
              </TableCell>
              <TableCell key="year" align="center">
                Ano
              </TableCell>
              <TableCell key="value" align="center">
                Valor
              </TableCell>
              <TableCell key="entry" align="center">
                Entrada
              </TableCell>
              <TableCell key="bank" align="center">
                Banco
              </TableCell>
              <TableCell key="status" align="center">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proposals?.map(
              (proposal) =>
                proposal.cpf && (
                  <TableRow key={`${proposal.cpf} ${proposal.dateTime}`} hover>
                    <TableCell align="center">
                      <Link
                        to={`/vendedor/${proposal.cpf}/${proposal.dateTime}`}
                      >
                        {proposal.cpf.replace(
                          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                          "$1.$2.$3-$4"
                        )}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {moment(proposal.dateTime).format(
                        "DD/MM/YYYY [às] HH:mm"
                      )}
                    </TableCell>
                    <TableCell align="center">{proposal.car_model}</TableCell>
                    <TableCell align="center">
                      {proposal.car_modelYear}
                    </TableCell>
                    <TableCell align="center">
                      {Number.parseFloat(proposal.car_value).toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {Number.parseFloat(
                        proposal.car_entryValue
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell align="center">{proposal.bank}</TableCell>
                    <TableCell align="center">{proposal.status}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Link to="/" className="prev">
        <KeyboardBackspaceIcon /> <span>Voltar</span>
      </Link>
    </div>
  );
}

export default Seller;
