import React from "react";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import "./style.css";
import DetailForm from "../../components/DetailForm";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link, useParams, useHistory } from "react-router-dom";
import { FaWhatsappSquare } from "react-icons/fa";
import { useAuth } from "../../hooks/auth";
import { IProposal } from "../../interfaces";
import { RequestService } from "../../services";

function BackOfficeDetails() {
  const { cpf, dateTime } = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const [proposal, setProposal] = React.useState<IProposal | null>(null);
  const [sellerPhone, setSellerPhone] = React.useState<string>("");

  React.useEffect(() => {
    if (user && cpf && dateTime) {
      if (user.seller_code) history.replace("/vendedor");
      else
        RequestService.get(cpf, dateTime, "").then((response) => {
          setProposal(response.data.result);
          setSellerPhone(
            response.data.seller_mobile_areaCode +
              response.data.seller_mobile_number
          );
        });
    }
  }, [cpf, dateTime, user, history]);
  console.log(cpf, dateTime, user);
  return (
    <div id="backOfficeDetailsPage" className="container">
      <div className="head">
        <h1>Detalhe do Back-Office</h1>
        <a
          href={`https://api.whatsapp.com/send?phone=55${sellerPhone}`}
          target="_blank"
          rel="noopener noreferrer"
          className="wppLink"
        >
          <FaWhatsappSquare className="wppIcon" />
        </a>
      </div>
      <DetailForm proposal={proposal} />
      <Link to="/backoffice" className="prev">
        <KeyboardBackspaceIcon /> <span>Voltar</span>
      </Link>
    </div>
  );
}

export default BackOfficeDetails;
