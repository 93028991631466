import React from "react";

import "./style.css";

interface IProps {
  financial: string;
  logo: string;
  financeOperationDetails?: any;
  onClick: () => void;
  buying: boolean;
}

const ResultCard: React.FC<IProps> = ({
  financial,
  logo,
  financeOperationDetails,
  onClick,
  buying,
}) => {
  return (
    <div
      className={`resultCardContainer ${
        financeOperationDetails ? "" : "unavailable"
      } ${buying ? "buying" : ""}`}
      title={financial}
      onClick={financeOperationDetails ? onClick : () => {}}
    >
      <div className="logoContainer">
        <img src={logo} alt="Bradesco" />
      </div>
      <div className="textContainer">
        {financeOperationDetails ? (
          <>
            <span className="installmentContainer">
              <span className="installmentNumber">
                {financeOperationDetails.installments}x{" "}
              </span>
              <span className="installment">parcelas de</span>
            </span>
            <span className="value">
              R${" "}
              <span className="number">
                {financeOperationDetails.value
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")}
              </span>
            </span>
          </>
        ) : (
          <span className="unavailable-message">INDISPONÍVEL</span>
        )}
      </div>
    </div>
  );
};

export default ResultCard;
