import React from "react";
import { IStepComponent } from "../../interfaces";
import { useHistory } from "react-router-dom";

function ApprovedCPF(props: IStepComponent) {
  const history = useHistory();
  return (
    <div id="approved-cpf" className="container">
      <div className="label">
        <div className="validation">
          UM CADASTRO JÁ FOI REALIZADO COM ESTE CPF. UTILIZE SEU E-MAIL E SENHA
          PARA TER ACESSO AO SISTEMA.
        </div>
        <div className="come-back">
          Caso precise redefinir a senha, escolha a opção “Esqueci minha senha”.
        </div>
      </div>
      <div className="button">
        <button
          className="full-width"
          onClick={() => history.replace("/login")}
        >
          Voltar para a tela de log in
        </button>
      </div>
    </div>
  );
}

export default ApprovedCPF;
