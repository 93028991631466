import { fetchWrapper as fetch } from ".";
import { apiURL } from "../config/env";
import { IFormType } from "../interfaces";

class SimulationService {
  static get({
    name,
    cpf,
    email,
    phone,
    uf,
    birthday,
    model,
    year,
    value,
    entryValue,
  }: IFormType) {
    const match = phone.match(/\(([^)]+)\)/);
    const formatedData = {
      client: {
        name,
        cpf: cpf.replace(/[.|-]/g, ""),
        email,
        address: { uf },
        birthday,
        phone: {
          areaCode: match && match?.length >= 2 ? match[1] : "",
          number: phone.substr(4).replace(/[ |-]/g, ""),
        },
      },
      car: {
        model,
        modelYear: year?.substr(0, 4),
        manufactureYear: year?.substr(0, 4),
        entryValue: Number.parseFloat(
          (entryValue || "0")
            ?.replace(".", "")
            ?.replace(",", ".")
            ?.replace("R$", "")
            .trim()
        ),
        value: Number.parseFloat(
          (value || "0")
            ?.replace(".", "")
            ?.replace(",", ".")
            ?.replace("R$", "")
            .trim()
        ),
      },
    };
    return fetch(`${apiURL}/financing-simulation`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formatedData),
    });
  }
}

export default SimulationService;
