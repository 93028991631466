import { fetchWrapper as fetch } from ".";
import { fipeURL } from "../config/env";

class FipeService {
  static getBrands() {
    return fetch(`${fipeURL}/carros/marcas`);
  }
  static getModels(brand?: string) {
    return fetch(`${fipeURL}/carros/marcas/${brand}/modelos`);
  }
  static getYears(brand?: string, model?: string) {
    return fetch(`${fipeURL}/carros/marcas/${brand}/modelos/${model}/anos`);
  }
}

export default FipeService;
