import React from "react";
import { IStepComponent } from "../../interfaces";

function ApprovedCPF(props: IStepComponent) {
  return (
    <div id="denied-cpf" className="container">
      <div className="label">
        <div className="validation">
          O DOCUMENTO ENVIADO NÃO É VÁLIDO OU NÃO FOI ACEITO NA VERIFICAÇÃO DE
          AUTENTICIDADE. VERIFIQUE SE OS DOCUMENTOS ESTÃO DENTRO DA VALIDADE E
          EM BOM ESTADO DE CONSERVAÇÃO E TENTE NOVAMENTE.
        </div>
      </div>
      <div className="button">
        <button className="full-width">Enviar documentos</button>
      </div>
    </div>
  );
}

export default ApprovedCPF;
