import React from "react";
import CurrencyInput from "react-currency-format";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import DriveEtaOutlinedIcon from "@material-ui/icons/DriveEtaOutlined";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import { IStepComponent } from "../../interfaces";
import { KeyboardBackspace, Send } from "@material-ui/icons";

function Step5({ setComponent, data, setData }: IStepComponent) {
  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }
  return (
    <div className="container">
      <div className="label">
        <div className="validation">INFORME OS DADOS DA LOJA</div>
      </div>
      <div className="text-field">
        <TextField
          fullWidth
          label="Nome da loja"
          name="shopName"
          value={data.shopName}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StoreOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <ReactInputMask
          value={data.phone_number}
          onChange={handleChange}
          mask="(99) 9999-9999"
        >
          {() => (
            <TextField
              fullWidth
              name="phone_number"
              label="Telefone fixo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </ReactInputMask>
        <TextField
          fullWidth
          label="Média de carros vendidos por mês"
          name="monthlySoldCars"
          value={data.monthlySoldCars}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DriveEtaOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Valor médio dos carros vendidos"
          name="averageValueCars"
          value={data.averageValueCars}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InsertChartOutlinedIcon />
              </InputAdornment>
            ),
            inputProps: {
              thousandSeparator: ".",
              decimalSeparator: ",",
              prefix: "R$ ",
            },
            inputComponent: CurrencyInput,
          }}
        />
        <TextField
          fullWidth
          label="Ano médio desses carros"
          name="averageYearCars"
          value={data.averageYearCars}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TimelineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="button">
        <button
          className="prev"
          type="button"
          onClick={() => {
            setComponent("Step4");
          }}
        >
          <KeyboardBackspace /> <span>Voltar</span>
        </button>
        <button type="submit" onClick={() => setComponent("Step6")}>
          <span>Continuar</span> <Send />
        </button>
      </div>
    </div>
  );
}

export default Step5;
