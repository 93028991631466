import React from "react";
import { TextField, InputAdornment, MenuItem } from "@material-ui/core";
import {
  PermIdentityOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
  PlaceOutlined,
  AssignmentOutlined,
} from "@material-ui/icons";
import { CEPService } from "../../services";
import CurrencyInput from "react-currency-format";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import WcOutlinedIcon from "@material-ui/icons/WcOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import Filter1OutlinedIcon from "@material-ui/icons/Filter1Outlined";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import { GrUserWorker } from "react-icons/gr";
import { BiHourglass } from "react-icons/bi";
import ReactInputMask from "react-input-mask";
import { UFList } from "../../config/UFList";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import DateMomentUtils from "@date-io/moment";
import Crop32OutlinedIcon from "@material-ui/icons/Crop32Outlined";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IStepComponent } from "../../interfaces";
import { Link, useHistory } from "react-router-dom";

function ClientsData({ setComponent, setData, data }: IStepComponent) {
  const history = useHistory();
  function handleDate(field: string) {
    function setDate(value: MaterialUiPickersDate) {
      setData(field, value ? value?.toString() : "");
    }
    return setDate;
  }

  function handleInput(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }

  const getAddress = (cep: string) => {
    CEPService.getAddress(cep.replace("-", "")).then((result) => {
      if (result.ok) {
        setData("street", result.data.logradouro);
        setData("neighborhood", result.data.bairro);
        setData("city", result.data.localidade);
        setData("uf", result.data.uf);
      }
    });
  };

  function handleCEP(e: React.FormEvent) {
    handleInput(e);
    const { value } = e.target as HTMLInputElement;
    const cepRegex = /\d{5}-\d{3}/;

    if (cepRegex.test(value)) {
      getAddress(value);
    }
  }

  return (
    <div id="clientsData">
      <form>
        <h1>Dados do Cliente</h1>
        <h3>Dados Gerais</h3>
        <TextField
          fullWidth
          name="name"
          label="Nome Completo"
          value={data.name}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityOutlined />
              </InputAdornment>
            ),
          }}
        />
        <div className="form-row">
          <ReactInputMask
            value={data.cpf}
            onChange={handleInput}
            mask="999.999.999-99"
          >
            {() => (
              <TextField
                fullWidth
                name="cpf"
                label="CPF"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
          <MuiPickersUtilsProvider utils={DateMomentUtils}>
            <KeyboardDatePicker
              autoOk
              name="birthday"
              onChange={handleDate("birthday")}
              value={data.birthday}
              label="Data de Nascimento"
              format="DD/MM/yyyy"
              InputAdornmentProps={{ position: "start" }}
              disableFuture
              invalidDateMessage="Data inválida"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-row">
          <ReactInputMask
            value={data.phone}
            onChange={handleInput}
            mask="(99) 99999-9999"
          >
            {() => (
              <TextField
                name="phone"
                label="Celular"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroidOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
          <ReactInputMask
            value={data.residencialPhone}
            onChange={handleInput}
            mask="(99) 9999-9999"
          >
            {() => (
              <TextField
                name="residencialPhone"
                label="Telefone residencial"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                helperText="Opcional"
              />
            )}
          </ReactInputMask>
        </div>
        <TextField
          fullWidth
          name="email"
          label="Email"
          value={data.email}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined />
              </InputAdornment>
            ),
          }}
        />
        <h3>Dados Complementares</h3>

        <TextField
          fullWidth
          value={data.mothersName}
          onChange={handleInput}
          name="mothersName"
          label="Nome da mãe"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentOutlined />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          value={data.fathersName}
          onChange={handleInput}
          name="fathersName"
          label="Nome do pai"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentOutlined />
              </InputAdornment>
            ),
          }}
        />
        <div className="form-row triple">
          <TextField
            name="gender"
            label="Sexo"
            select
            value={data.gender}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WcOutlinedIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="feminino">Feminino</MenuItem>
            <MenuItem value="masculino">Masculino</MenuItem>
          </TextField>
          <TextField
            value={data.rg}
            onChange={handleInput}
            fullWidth
            name="rg"
            label="Número do RG/RNE"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentIndOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <MuiPickersUtilsProvider utils={DateMomentUtils}>
            <KeyboardDatePicker
              autoOk
              name="emissionDate"
              onChange={handleDate("emissionDate")}
              value={data.emissionDate}
              label="Data de Emissão"
              format="DD/MM/yyyy"
              InputAdornmentProps={{ position: "start" }}
              disableFuture
              invalidDateMessage="Data inválida"
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="form-row triple">
          <TextField
            fullWidth
            name="emissionAgency"
            label="Órgão expedidor"
            value={data.emissionAgency}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LabelOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="documentUF"
            label="UF do documento"
            value={data.documentUF}
            onChange={handleInput}
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlined />
                </InputAdornment>
              ),
            }}
          >
            {UFList.map((uf: string) => (
              <MenuItem key={uf} value={uf}>
                {uf}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            name="nationality"
            label="Nacionalidade"
            value={data.nationality}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlined />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            value={data.naturalness}
            onChange={handleInput}
            name="naturalness"
            label="Naturalidade"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="maritalStatus"
            label="Estado civil"
            value={data.maritalStatus}
            onChange={handleInput}
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Crop32OutlinedIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="solteiro">Solteiro (a)</MenuItem>
            <MenuItem value="casado">Casado (a)</MenuItem>
            <MenuItem value="divorciado">Divorciado (a)</MenuItem>
            <MenuItem value="viuvo">Viúvo (a)</MenuItem>
          </TextField>
        </div>

        <TextField
          fullWidth
          value={data.spousesName}
          onChange={handleInput}
          name="spousesName"
          label="Nome completo do cônjuge"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentOutlined />
              </InputAdornment>
            ),
          }}
        />
        <div className="form-row">
          <ReactInputMask
            value={data.spousesCpf}
            onChange={handleInput}
            mask="999.999.999-99"
          >
            {() => (
              <TextField
                fullWidth
                name="spousesCpf"
                label="CPF do cônjuge"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
          <TextField
            value={data.patrimonyTotalValue}
            fullWidth
            onChange={handleInput}
            name="patrimonyTotalValue"
            label="Valor total do patrimônio"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>
              ),
              inputProps: {
                thousandSeparator: ".",
                decimalSeparator: ",",
                prefix: "R$ ",
              },
              inputComponent: CurrencyInput,
            }}
          />
        </div>

        <div className="form-row">
          <TextField
            value={data.natureOfOcupation}
            onChange={handleInput}
            name="natureOfOcupation"
            label="Natureza da ocupação ou trabalho"
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkOutlineOutlinedIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="empresario">Empresário (a)</MenuItem>
            <MenuItem value="autonomo">Autônomo (a)</MenuItem>
            <MenuItem value="assalariado">Assalariado (a)</MenuItem>
          </TextField>
          <TextField
            fullWidth
            value={data.ocupationDetail}
            onChange={handleInput}
            name="ocupationDetail"
            label="Detalhe do trabalho"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorkOutlineOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            fullWidth
            name="ocupationTime"
            label="Tempo de serviço em anos+meses"
            value={data.ocupationTime}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BiHourglass />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            value={data.totalMonthlyIncome}
            onChange={handleInput}
            name="totalMonthlyIncome"
            label="Renda mensal total"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>
              ),
              inputProps: {
                thousandSeparator: ".",
                decimalSeparator: ",",
                prefix: "R$ ",
              },
              inputComponent: CurrencyInput,
            }}
          />
          {/* <TextField
            fullWidth
            value={data.totalMonthlyIncome}
            onChange={handleInput}
            name="totalMonthlyIncome"
            label="Renda mensal total"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>
              ),
            }}
          /> */}
        </div>
        <ReactInputMask
          value={data.comercialPhone}
          onChange={handleInput}
          mask="(99) 9999-9999"
        >
          {() => (
            <TextField
              name="comercialPhone"
              label="Telefone comercial (opcional)"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </ReactInputMask>
        <div className="form-row">
          <TextField
            fullWidth
            name="reference"
            value={data.reference}
            onChange={handleInput}
            label="Nome de uma referência"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeWorkOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <ReactInputMask
            value={data.referencePhone}
            onChange={handleInput}
            mask="(99) 99999-9999"
          >
            {() => (
              <TextField
                name="referencePhone"
                label="Telefone da referência"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
        </div>
        <h3>Endereço</h3>
        <div className="form-row">
          <ReactInputMask
            value={data.cep}
            onChange={handleCEP}
            mask="99999-999"
          >
            {() => (
              <TextField
                name="cep"
                label="CEP"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkunreadMailboxOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
          <TextField
            name="propertySituation"
            label="Situação do imóvel"
            select
            value={data.propertySituation}
            onChange={handleInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeOutlinedIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="familiar">Familiar</MenuItem>
            <MenuItem value="proprio">Próprio</MenuItem>
            <MenuItem value="alugado">Alugado</MenuItem>
          </TextField>
        </div>
        <TextField
          fullWidth
          name="neighborhood"
          label="Bairro"
          value={data.neighborhood}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeWorkOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className="form-row">
          <TextField
            fullWidth
            name="street"
            value={data.street}
            onChange={handleInput}
            label="Rua ou avenida"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            name="number"
            value={data.number}
            onChange={handleInput}
            label="Número"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Filter1OutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TextField
          fullWidth
          value={data.complement}
          onChange={handleInput}
          name="complement"
          label="Complemento"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddLocationOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className="form-row">
          <TextField
            fullWidth
            value={data.city}
            onChange={handleInput}
            name="city"
            label="Cidade"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="uf"
            value={data.uf}
            onChange={handleInput}
            label="UF"
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlined />
                </InputAdornment>
              ),
            }}
          >
            {UFList.map((uf: string) => (
              <MenuItem key={uf} value={uf}>
                {uf}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="button">
          <button
            className="prev"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon /> <span>Voltar</span>
          </button>
          <button
            className="next"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setComponent("CarData");
            }}
          >
            <span>Próximo</span> <ArrowForwardIcon />
          </button>
        </div>
      </form>
    </div>
  );
}
export default ClientsData;
