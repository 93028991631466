import React from "react";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import {
  AssignmentOutlined,
  EmailOutlined,
  PhoneAndroidOutlined,
} from "@material-ui/icons/";
import { IStepComponent } from "../../interfaces";
import { UserService } from "../../services";
import { useToast } from "../../hooks/toast";

function Step2({ setComponent, data, setData }: IStepComponent) {
  const { addToast } = useToast();
  function handleChange(e: React.FormEvent) {
    const { name, value } = e.target as HTMLInputElement;
    setData(name, value);
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    UserService.startCAF(data.cpf, data.mobile_number, data.email)
      .then((response) => {
        if (response.data.success) {
          alert(
            "Obrigado! Um link foi enviado via SMS para continuidade do cadastro através do Combate à Fraude!"
          );
          setComponent("Step1");
        } else {
          addToast({
            title: "Erro",
            description: response.data.message,
            type: "error",
          });
        }
      })
      .catch((e) =>
        addToast({
          title: "Erro",
          description: e.message,
          type: "error",
        })
      );
  }
  console.log(data);
  return (
    <div id="step2" className="container">
      <div className="label">
        <div className="validation">APÓS TERMINAR A VALIDAÇÃO</div>
        <div className="come-back">
          Retorne a esta página para continuar o cadastro.
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="text-field">
          <TextField
            fullWidth
            name="email"
            value={data.email}
            onChange={handleChange}
            label="E-mail"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <ReactInputMask
            value={data.mobile_number}
            onChange={handleChange}
            mask="(99) 99999-9999"
          >
            {() => (
              <TextField
                fullWidth
                label="Celular"
                name="mobile_number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroidOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
        </div>
        <div className="button">
          <button type="submit">Enviar documentos</button>
        </div>
      </form>
    </div>
  );
}

export default Step2;
