import React from "react";
import ReactInputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { AssignmentOutlined } from "@material-ui/icons/";
import { IStepComponent } from "../../interfaces";
import { UserService } from "../../services";
import { useToast } from "../../hooks/toast";

function Step1({ setComponent, data, setData }: IStepComponent) {
  const { addToast } = useToast();
  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    UserService.preSignUp(data.cpf)
      .then((response) => {
        switch (response.data.reason) {
          case 1: //First time
            setComponent("Step2");
            break;
          case 2: //Validation not done
            setComponent("Step3");
            break;
          case 3:
          case 4: //Validated
            setData("sellerCPF", response.data.cpf);
            setData("sellerName", response.data.name);
            setData("email", response.data.email);
            setData(
              "mobile_number",
              `(${response.data.mobile_areaCode}) ${response.data.mobile_number}`
            );

            setComponent("Step4");
            break;
          case 5: //Signed Up
            setComponent("ApprovedCPF");
            break;
          case 6: //Denied by CAF
            setComponent("DeniedCPF");
            break;
        }
      })
      .catch((e) =>
        addToast({
          title: "Erro",
          description: e.message,
          type: "error",
        })
      );
  }

  function handleChange(e: React.FormEvent) {
    const { value } = e.target as HTMLInputElement;
    setData("cpf", value);
  }

  return (
    <div id="step1" className="container">
      <div className="label">
        <div className="validation">INFORME O SEU CPF</div>
        <div className="come-back">para dar início ao processo de cadastro</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="text-field">
          <ReactInputMask
            value={data.cpf}
            onChange={handleChange}
            mask="999.999.999-99"
          >
            {() => (
              <TextField
                fullWidth
                label="CPF"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </ReactInputMask>
        </div>
        <div className="button">
          <button className="full-width" type="submit">
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
