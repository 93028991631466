import React from "react";
import { AiOutlineBank, AiFillBank } from "react-icons/ai";
import { FaPiggyBank } from "react-icons/fa";
import { MdLabelOutline } from "react-icons/md";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";

import { IStepComponent } from "../../interfaces";
import { useHistory } from "react-router-dom";

function Step11({ setComponent }: IStepComponent) {
  const history = useHistory();
  return (
    <div id="step11" className="container">
      <div className="label">
        <div className="validation">
          SEU CADASTRO FOI REALIZADO COM SUCESSO!
        </div>
        <div className="come-back">Volte à tela inicial e faça log in</div>
      </div>

      <div className="button">
        <button
          className="full-width"
          onClick={() => history.replace("/login")}
        >
          Voltar à tela inicial
        </button>
      </div>
    </div>
  );
}

export default Step11;
