import React from "react";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import userIcon from "../../assets/img/icons/user.png";
import passwordIcon from "../../assets/img/icons/password.png";
import ClosedEyeIcon from "@material-ui/icons/VisibilityOffOutlined";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import "./style.css";
import { InputAdornment } from "@material-ui/core";
import { useAuth } from "../../hooks/auth";

function Login() {
  const [hiddenPassword, setHiddenPassword] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const history = useHistory();
  const { signed, signIn } = useAuth();

  React.useEffect(() => {
    if (signed) history.replace("/");
  }, [signed]);

  function toggleVisibility() {
    setHiddenPassword(!hiddenPassword);
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const response = await signIn({ email, password });
    if (!response.ok) setError(response?.message || "");
  }

  function handleEmail(e: React.FormEvent) {
    setEmail((e.target as HTMLInputElement).value);
  }
  function handlePassword(e: React.FormEvent) {
    setPassword((e.target as HTMLInputElement).value);
  }

  return (
    <div id="login" className="container">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form">
          <div className="textField">
            <TextField
              fullWidth
              label="Email ou usuário"
              value={email}
              onChange={handleEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={userIcon}
                      alt="Ícone de usuário"
                      className="icon"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              type={hiddenPassword ? "password" : "text"}
              label="Senha"
              value={password}
              onChange={handlePassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={passwordIcon}
                      alt="Ícone de senha"
                      className="icon"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <span className="passwordButton" onClick={toggleVisibility}>
                      {hiddenPassword ? <EyeIcon /> : <ClosedEyeIcon />}
                    </span>
                  </InputAdornment>
                ),
              }}
              helperText={error}
            />
          </div>
          <div className="button">
            <button className="loginButton" type="submit">
              Entrar
            </button>
            <Link to="/esqueci-a-senha" className="link">
              Esqueci minha senha
            </Link>
            <Link to="/cadastro" className="idButton">
              Crie sua conta
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
